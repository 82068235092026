<template>
  <div></div>
</template>

<script>
export default {
    mounted() {
        sessionStorage.setItem('keys',this.$route.query.token)
         var key=sessionStorage.getItem('keys')
         console.log(key);
        if(key==undefined){
          this.$router.push("/")
        }else{
          this.$router.push("/homes/merchant_order")
        }
          },
    methods: {
    },
}
</script>

<style>

</style>